import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
:root{
  font-size: 10px;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html, body, #root {
  position:relative;
  min-height: 100vh;
}
body{
  color: #333;
  font-family: ${(props) => props.theme.fontFamily.main};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  letter-spacing: 0.5px;
  background: ${(props) =>
    `linear-gradient(to right bottom, ${props.theme.iceCold}, ${props.theme.splindle})`};
}
`;

export default GlobalStyle;

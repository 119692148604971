import React from "react";
import styled from "styled-components";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
const Wrapper = styled.div`
  display: flex;
  position: relative;
  height: 100vh;
  max-width: ${(props) => props.theme.maxWidth};
  margin: 0 auto;
  padding: 0 4rem;
  flex-direction: column;
  .children-wrapper {
    position: relative;
    flex: 1;
  }
  @media only screen and (max-width: 500px) {
    padding: 0 2rem;
  }
`;

export default function MainLayout({ children }) {
  return (
    <Wrapper>
      {/* <Confetti width={window.outerWidth} height={window.outerHeight} /> */}
      <Navbar />
      <div className="children-wrapper">{children}</div>
      <Footer />
    </Wrapper>
  );
}

import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import GlobalStyle from "./GlobalStyle";
import MainLayout from "./layouts/MainLayout";
import { HomePage } from "./pages";

function App() {
  return (
    <BrowserRouter>
      <GlobalStyle />
      <Switch>
        <Route
          path="/"
          render={() => (
            <MainLayout>
              <HomePage />
            </MainLayout>
          )}
        />
      </Switch>
    </BrowserRouter>
  );
}

export default App;

import React from "react";
import styled from "styled-components";

const Button = styled.button`
  position: relative;
  background: none;
  border: none;
  outline: none;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export default function IconButton({ className, ...otherProps }) {
  return (
    <Button className={className} {...otherProps}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="34.875"
        height="34.875"
        viewBox="0 0 34.875 34.875"
      >
        <path
          id="Icon_awesome-arrow-circle-right"
          data-name="Icon awesome-arrow-circle-right"
          d="M18,.563A17.438,17.438,0,1,1,.563,18,17.434,17.434,0,0,1,18,.563Zm-2.032,10.1,5.309,5.091H8.438A1.683,1.683,0,0,0,6.75,17.438v1.125A1.683,1.683,0,0,0,8.438,20.25H21.277l-5.309,5.091a1.689,1.689,0,0,0-.028,2.412l.773.766a1.681,1.681,0,0,0,2.384,0l9.33-9.323a1.681,1.681,0,0,0,0-2.384L19.1,7.474a1.681,1.681,0,0,0-2.384,0l-.773.766a1.7,1.7,0,0,0,.028,2.419Z"
          transform="translate(-0.563 -0.563)"
          fill="#addbd1"
        />
      </svg>
    </Button>
  );
}

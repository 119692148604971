import React, { useEffect, useRef } from "react";
import styled, { keyframes, css } from "styled-components";
import Ball from "assets/ball3@2x.png";

const rotate = keyframes`
  from {
      transform: rotate(0deg);
      left: 0;
    }

  to {
    transform: rotate(360deg);
    left: 30px;
  }
`;

const LoadingScreen = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom right, #e8e9a1 30%, #63c2a5);
  z-index: 2000;
  right: ${({ isLoading }) => (isLoading ? "0" : "100%")};
  transition: all 0.5s ease-in-out;
`;

const SpinnerWrapper = styled.span`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  letter-spacing: 0.1em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
`;

const SpinnerImage = styled.img`
  position: relative;
  width: 30px;
  ${({ isLoading }) =>
    isLoading &&
    css`
      animation: ${rotate} 0.8s ease infinite alternate;
    `};
`;
function LoadingSpinner({ isLoading }) {
  const ref = useRef(null);
  useEffect(() => {
    ref.current.addEventListener(
      "transitionend",
      () => {
        document.querySelector("body").style.overflow = "initial";
        ref.current.style.display = "none";
      },
      { once: true }
    );
    document.querySelector("body").style.overflow = "hidden";
    // return () => {
    //   document.querySelector("body").style.overflow = "initial";
    // };
  }, []);

  return (
    <LoadingScreen className="helloo" isLoading={isLoading} ref={ref}>
      <SpinnerWrapper>
        Loading <SpinnerImage src={Ball} isLoading={isLoading} />
      </SpinnerWrapper>
    </LoadingScreen>
  );
}

export default LoadingSpinner;

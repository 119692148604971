import React from "react";
import styled from "styled-components";

const FooterWrapper = styled.footer`
  position: relative;
  padding: 1.5rem 1rem;
  text-align: center;
`;
const Copyright = styled.p`
  margin-bottom: 10px;
`;

const Credits = styled.p`
  a {
    color: #333;
    text-decoration: none;
  }
`;

function Footer() {
  return (
    <FooterWrapper>
      <Copyright>© Kenneth Hau 2020. All Rights Reserved</Copyright>
      <Credits>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://icons8.com/icons/set/pokemon"
        >
          Pokemon icon
        </a>{" "}
        and{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://icons8.com/icons/set/animation"
        >
          Animation icon{" "}
        </a>
        made by{" "}
        <a target="_blank" rel="noreferrer" href="https://icons8.com">
          Icons8
        </a>
      </Credits>
    </FooterWrapper>
  );
}

export default Footer;

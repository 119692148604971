import React from "react";
import styled from "styled-components";
import Logo from "./Logo";
const StyledNav = styled.nav`
  display: flex;
  flex-direction: row;
  padding: 25px 0 40px;
  /* height: 160px; */
`;

export default function Navbar() {
  return (
    <StyledNav>
      <Logo />
    </StyledNav>
  );
}

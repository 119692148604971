export const fontFamily = {
  logo: "'Swanky and Moo Moo', cursive",
  main: "'Sanchez', serif",
};

export const maxWidth = "1920px";

export const iceCold = "#A3DDCB";
export const splindle = "#CAD6E2";
export const drover = "#FCFC9B";

import styled, { keyframes, css } from "styled-components";

const wheelSpinTime = 3;

const ballRotateTime = 2;
// const ballRotateTime = 3;
// const msgTiltTime = 1.9;
const msgTiltTime = 1.5;
const x = 1024;
const y = 9999;
const deg = Math.floor(Math.random() * (x - y) + y);
const randomRotate = keyframes`
  from {
      transform: rotate(0deg);
    }

  to {
    transform: rotate(${deg}deg);
  }
`;

const rotateToMove = keyframes`
  from {
      transform: rotate(0deg);
      bottom: 150px;
    }
  
  40% {
    bottom: 60px;
    transform: rotate(380deg);
    left: 50%;
  }
  80% {
    bottom: 53px;
    transform: rotate(720deg);
    left: 60%;
  }
  100% {
    bottom: 60px;
    transform: rotate(360deg);
    left: 50%;
  }
`;

const rotateToMoveSd = keyframes`
  from {
      transform: rotate(0deg);
      bottom: 150px;
    }
  
  40% {
    bottom: 43px;
    transform: rotate(380deg);
    left: 50%;
  }
  80% {
    bottom: 33px;
    transform: rotate(720deg);
    left: 60%;
  }
  100% {
    bottom: 43px;
    transform: rotate(360deg);
    left: 50%;
  }
`;
const tilt = keyframes`
  from {
    top: 0;
  }
  20% {
    top: 15px;
  }
  50% {
    top: 20px;
  }
  70% {
    top: 20px;
    transform: rotate(5deg);
  }
  100% {
    top: 20px;
    transform: rotate(0deg);
  }
`;

const popUp = keyframes`
  from{
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
export const Home = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;

  .main-content {
    position: relative;
  }
  .ball {
    position: absolute;
    width: 12%;
    bottom: 150px;
    left: 50%;
    ${(props) =>
      props.isSpinning &&
      css`
        -moz-animation: ${rotateToMove} ${ballRotateTime}s
          cubic-bezier(0.64, 0.57, 0.67, 1.53) ${wheelSpinTime - 0.5}s forwards;
        -webkit-animation: ${rotateToMove} ${ballRotateTime}s
          cubic-bezier(0.64, 0.57, 0.67, 1.53) ${wheelSpinTime - 0.5}s forwards;
        animation: ${rotateToMove} ${ballRotateTime}s
          cubic-bezier(0.64, 0.57, 0.67, 1.53) ${wheelSpinTime - 0.5}s forwards;
      `}

    @media only screen and (max-width: 330px) {
      ${(props) =>
        props.isSpinning &&
        css`
          -moz-animation: ${rotateToMoveSd} ${ballRotateTime}s
            cubic-bezier(0.64, 0.57, 0.67, 1.53) ${wheelSpinTime - 0.5}s
            forwards;
          -webkit-animation: ${rotateToMoveSd} ${ballRotateTime}s
            cubic-bezier(0.64, 0.57, 0.67, 1.53) ${wheelSpinTime - 0.5}s
            forwards;
          animation: ${rotateToMoveSd} ${ballRotateTime}s
            cubic-bezier(0.64, 0.57, 0.67, 1.53) ${wheelSpinTime - 0.5}s
            forwards;
        `}
    }
  }
  .lucky-draw-wheel-wrapper {
    position: relative;
    margin: 5rem auto 0;
    text-align: center;
    img {
      &.wheel {
        width: 100%;
        border-radius: 9999px;
        ${(props) =>
          props.isSpinning &&
          css`
            -moz-animation: ${randomRotate} ${wheelSpinTime}s ease forwards;
            -webkit-animation: ${randomRotate} ${wheelSpinTime}s ease forwards;
            animation: ${randomRotate} ${wheelSpinTime}s ease forwards;
          `}

        @media only screen and (max-width: 800px) {
          width: 80%;
        }
        @media only screen and (max-width: 500px) {
          width: 70%;
        }
      }
      &.pin {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        user-select: none;
        cursor: pointer;
        z-index: 2;

        &.smiley {
          opacity: ${(props) => (props.isSpinning ? 1 : 0)};
          z-index: 1;
        }
        &.normal {
          opacity: ${(props) => (!props.isSpinning ? 1 : 0)};
          &:hover {
            opacity: 0;
            & ~ .smiley {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .num-of-people {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    ${(props) =>
      props.isSpinning &&
      css`
        -moz-animation: ${tilt} ${msgTiltTime}s
          cubic-bezier(0.64, 0.57, 0.67, 1.53) ${wheelSpinTime - 0.15}s forwards;
        -webkit-animation: ${tilt} ${msgTiltTime}s
          cubic-bezier(0.64, 0.57, 0.67, 1.53) ${wheelSpinTime - 0.15}s forwards;
        animation: ${tilt} ${msgTiltTime}s cubic-bezier(0.64, 0.57, 0.67, 1.53)
          ${wheelSpinTime - 0.15}s forwards;
      `}

    p {
      font-size: 1.8rem;
      background: #fff;
      border-radius: 10px;
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
      padding: 1.26rem 2.7rem;
      position: relative;
      left: -40px;
      z-index: -1;
      span {
        position: relative;
      }
      span::before {
        content: "";
        position: absolute;
        bottom: -1px;
        width: 100%;
        height: 2px;
        border-radius: 999px;
        background: ${(props) => props.theme.iceCold};
      }
    }
    @media only screen and (max-width: 500px) {
      p {
        font-size: 1.6rem;
        white-space: nowrap;
      }
    }

    @media only screen and (max-width: 330px) {
      img {
        width: 80px;
      }
      p {
        left: -28px;
        font-size: 1.3rem;
        white-space: initial;
      }
    }
  }
`;

export const InputField = styled.div`
  position: relative;
  display: flex;
  background: #fff;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  border-radius: 1rem;
  width: 320px;
  padding: 0.6rem 0;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  ${(props) =>
    props.submittedNumber &&
    css`
      border: 2px solid #63c2a5;
    `}

  input {
    background: none;
    border: none;
    outline: none;
    font-family: ${(props) => props.theme.fontFamily.main};
    font-size: 2.5rem;
    padding: 0.5em 0.5em;
    width: 80%;
    &::placeholder {
      color: #8b8b8b;
    }
  }
  @media only screen and (max-width: 550px) {
    width: 100%;
    input {
      font-size: 2rem;
    }
  }
`;

export const ResultBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  background: #fff;
  width: 100%;
  height: 150px;
  font-size: 3rem;
  padding: 0.7em 0.9em;
  border-radius: 10px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  animation: ${popUp} 0.1s ease-in-out forwards 0.3s;
  .close-btn {
    align-self: flex-end;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }

  .message {
    position: relative;
    flex: 1;
    align-self: stretch;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 4rem;
      font-weight: 600;
    }
    span {
      color: ${(props) => props.theme.iceCold};
    }
    img {
      /* margin-left: 10px; */
      position: relative;
      top: -15px;
      width: 30%;
    }
    @media only screen and (max-width: 330px) {
      h1 {
        font-size: 3rem;
      }
    }
  }
`;

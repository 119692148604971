import React, { useRef, useState, useEffect } from "react";
import Confetti from "react-confetti";
import { Home, InputField, ResultBox } from "./HomePage.elements";
import Wheel from "assets/wheel@2x.png";
import PikaChuNormal from "assets/pikachu-normal@2x.png";
import PikaChuSmiley from "assets/pikachu-smiley@2x.png";
import Mickey from "assets/mickey@2x.png";
import Close from "assets/close@2x.png";
import Dance from "assets/dance.gif";
import IconButton from "components/IconButton";
import LoadingSpinner from "components/LoadingSpinner";
const chooseBall = () => {
  return require(`assets/ball${Math.floor(Math.random() * 4) + 1}@2x.png`)
    .default;
};

export default function HomePage() {
  const [isLoading, setIsLoading] = useState(true);
  const [confettiWidth, setConfettiWidth] = useState(window.innerWidth);
  const [confettiHeight, setConfettiHeight] = useState(window.innerHeight);
  const [showConfetti, setShowConfetti] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);
  const [numberStr, setNumberStr] = useState("");
  const [submittedNumber, setSubmittedNumber] = useState("");
  const [isResultShown, setIsResultShown] = useState(false);
  const chosenNumber = useRef(0);
  const Ball = useRef(chooseBall());
  const spinWheel = () => {
    setIsSpinning(true);
    Ball.current = chooseBall();
    document.getElementById("ball").addEventListener(
      "animationend",
      () => {
        const result = Math.floor(Math.random() * submittedNumber) + 1;
        chosenNumber.current = result;
        setShowConfetti(true);
        setIsResultShown(true);
      },
      { once: true }
    );
  };

  const submitNumber = (e) => {
    if (e.key !== "Enter" && e.type === "keypress") {
      return;
    }
    const numberInt = parseInt(numberStr);
    if (isNaN(numberInt)) {
      return;
    }

    setSubmittedNumber(numberInt);
    setNumberStr("");
  };

  useEffect(() => {
    const reactToSizeChange = () => {
      if (window.innerWidth !== confettiWidth) {
        setConfettiWidth(window.innerWidth);
      }
      if (window.innerHeight !== confettiHeight) {
        setConfettiHeight(window.innerHeight);
      }
    };
    const windowSizeListener = window.addEventListener(
      "resize",
      reactToSizeChange
    );
    return () => {
      window.removeEventListener("resize", windowSizeListener);
    };
    // eslint-disable-next-line
  }, []);
  return (
    <Home isSpinning={isSpinning}>
      <LoadingSpinner isLoading={isLoading} />
      {showConfetti && (
        <Confetti
          width={confettiWidth}
          height={confettiHeight}
          style={{ position: "fixed", zIndex: "1001" }}
          recycle={true}
        />
      )}
      <InputField submittedNumber={submittedNumber}>
        <input
          type="text"
          value={numberStr}
          onChange={(e) => setNumberStr(e.target.value)}
          onKeyPress={submitNumber}
          placeholder="Enter a number"
        />
        <IconButton className="hello" onClick={submitNumber} />
      </InputField>
      <div className="main-content">
        <img id="ball" className="ball" src={Ball.current} alt="Ball" />
        <div className="lucky-draw-wheel-wrapper">
          {isResultShown && (
            <ResultBox isResultShown={isResultShown}>
              <img
                className="close-btn"
                src={Close}
                alt="Close Message"
                onClick={() => {
                  setIsSpinning(false);
                  setShowConfetti(false);
                  setIsResultShown(false);
                }}
              />
              <div className="message">
                <h1>
                  <span>No. {chosenNumber.current}</span>
                  <img src={Dance} alt="A cute cartoon dancing" />
                </h1>
              </div>
            </ResultBox>
          )}
          <img
            id="wheel"
            className="wheel"
            src={Wheel}
            alt="Lucky Draw"
            onLoad={() => setIsLoading(false)}
          />
          <img
            className="pin normal"
            src={PikaChuNormal}
            alt="Spin"
            onClick={spinWheel}
          />
          <img className="pin smiley" src={PikaChuSmiley} alt="Spin" />
        </div>
        <div className="num-of-people">
          <img src={Mickey} alt="Mickey" />
          <p>
            Total Number: <span>{submittedNumber ? submittedNumber : 0}</span>
          </p>
        </div>
      </div>
    </Home>
  );
}

import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: ${(props) => props.theme.fontFamily.logo};
  justify-content: flex-start;
  align-items: flex-start;
  user-select: none;
  h1 {
    all: unset;
    font-size: 7rem;
    color: ${(props) => props.theme.drover};
  }
  h4 {
    all: unset;
    position: relative;
    font-weight: 400;
    font-size: 2.5rem;
    top: -10px;
  }

  @media only screen and (max-width: 330px) {
    h1 {
      font-size: 5rem;
    }
    h4 {
      font-size: 2rem;
    }
  }
`;

export default function Logo() {
  return (
    <Wrapper>
      <h1>LUCKYme</h1>
      <h4>Designed for kids</h4>
    </Wrapper>
  );
}
